import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';




import labvr1 from './labvr/labvr1.jpg'
import labvr2 from './labvr/labvr2.jpg'
import labvr3 from './labvr/labvr3.jpg'




import aframe from '../img/icons/aframe.png'
import js from '../img/icons/js.jpg'
import blender from '../img/icons/blender.png'

const Labvr = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | LabVR';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="Labvr"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                <iframe width="90%" height="505px" src="https://www.youtube.com/embed/DRfM2er-LkM" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
              <Col sxl={6} md={8} className="containerMarginCenter paddingBottom">
                <h3>De opdracht:</h3>
                <p>In dit project maak je een web-based experience, waarin Virtual Reality wordt gebruikt, voor een mobiel apparaat voorzien van Google Cardboard (of een vergelijkbare headset) <br/> <br/> De VR-beleving moet natuurlijk wel iets toevoegen aan de taak (zie de beoordelingseisen). Simpelweg een generiek gerecht maken in VR is echt onvoldoende. </p>
              </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

              <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={labvr1} />
                </div>
                <div>
                    <img src={labvr2} />
                </div>
                <div>
                    <img src={labvr3} />
                </div>

            </Carousel>

              </div>
              </Col>
              <Col className="mobile">
                <div>
                    <img src={labvr1} />
                </div>
                <div>
                    <img src={labvr2} />
                </div>
                <div>
                    <img src={labvr3} />
                </div>

              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>

            <Row></Row>
                <Col className="center flexMiddle">
                  <img src={aframe} width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={js}width="80px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={blender} width="125px" height="auto"/>
                </Col>



            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Labvr;
