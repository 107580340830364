import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';




import brusco1 from './brusco/brusco1.jpg'
import brusco2 from './brusco/brusco2.jpg'
import brusco3 from './brusco/brusco3.jpg'
import brusco4 from './brusco/brusco4.jpg'
import brusco5 from './brusco/brusco5.jpg'





import anycubic from '../img/icons/anycubic.png'
import arduino from '../img/icons/arduino.svg'
import illustrator from '../img/icons/illustrator.png'
import fusion360 from '../img/icons/Fusion360.jpg'

const Brusco = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | Brusco';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="Brusco"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                <img src={brusco1} width="90%" height="auto" />
              </Col>
              <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                <h3>De opdracht:</h3>
                <p>Jullie opdracht is om een IoT toepassing te ontwikkelen voorin of rond huis. Binnen dit thema mogen jullie zelf  verzinnen  wat  het thema  wordt,  met  de  voorwaarde  dat  je  creatief  denkt.  Iets  verzinnen  voor studentenhuizen? Natuurlijk! Automatische ramenwasser? Gaaf! Robotstofzuiger? Dit bestaat al... <br/><br/>De toepassing die jullie gaan ontwikkelen moet uit drie delen bestaan: een stuk hardware (dit mogen zowel sensoren als actuatoren zijn, of een combinatie van beiden), een backend die data verzameld, en een front-end  waarin  de  gebruiker  op  een  gebruikersvriendelijke  manier  kan  interacteren  met  de  hardware. Uiteindelijk  moet  jullie  toepassing zelfstandig  kunnen  draaien,  duszonder  tussenkomst  van  jullie  als projectgroepof anderen.</p>
              </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

              <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={brusco2} />
                </div>
                <div>
                    <img src={brusco3} />
                </div>
                <div>
                    <img src={brusco4} />
                </div>
                <div>
                    <img src={brusco5} />
                </div>

            </Carousel>

              </div>

              </Col>
              <Col className="mobile">
                <div>
                    <img src={brusco2} />
                </div>
                <div>
                    <img src={brusco3} />
                </div>
                <div>
                    <img src={brusco4} />
                </div>
                <div>
                    <img src={brusco5} />
                </div>
              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>

              <Row></Row>
                <Col className="center flexMiddle">
                  <img src={anycubic} width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={arduino}width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={illustrator} width="100px" height="auto"/>
                </Col>
                <Col className="center flexMiddle">
                  <img src={fusion360}width="200px" height="auto" />
                </Col>

            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Brusco;
