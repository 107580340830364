import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';




import telefoon1 from './telefoon/telefoon1.jpg'
import telefoon2 from './telefoon/telefoon2.jpg'
import telefoon3 from './telefoon/telefoon3.jpg'
import telefoon4 from './telefoon/telefoon4.jpg'
import telefoon5 from './telefoon/telefoon5.jpg'
import telefoon6 from './telefoon/telefoon6.jpg'






import anycubic from '../img/icons/anycubic.png'
import arduino from '../img/icons/arduino.svg'
import fusion360 from '../img/icons/Fusion360.jpg'

const Telefoon = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | Telefoon';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="Telefoon"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                <iframe width="90%" height="505px" src="https://www.youtube.com/embed/rkvmQ9y3XAg" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               </Col>
               <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                 <h3>De opdracht: </h3>
                 <p>Mijn moeder werkt voor Ipse de Bruggen, zij heeft een video voorbij zien komen van een oudere telefoon met muziek van vroeger hier op. Omdat de ouderen bekend waren met een draai telefoon werdt deze gebruik. Mijn moeder was benieuwd of ik dit ook zou kunnen maken maar dan voor de bewoners van Ipse de Bruggen</p>
               </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

              <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom carouselFotos">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={telefoon1} />
                </div>
                <div>
                    <img src={telefoon2} />
                </div>
                <div>
                    <img src={telefoon3} />
                </div>
                <div>
                    <img src={telefoon4} />
                </div>
                <div>
                    <img src={telefoon5} />
                </div>
                <div>
                    <img src={telefoon6} />
                </div>

            </Carousel>

              </div>
              </Col>
              <Col className="mobile">
                <div>
                    <img src={telefoon1} />
                </div>
                <div>
                    <img src={telefoon2} />
                </div>
                <div>
                    <img src={telefoon3} />
                </div>
                <div>
                    <img src={telefoon4} />
                </div>
                <div>
                    <img src={telefoon5} />
                </div>
                <div>
                    <img src={telefoon6} />
                </div>

              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>

              <Row></Row>
                <Col className="center flexMiddle">
                  <img src={anycubic} width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={arduino}width="100px" height="auto" />
                </Col>

                <Col className="center flexMiddle">
                  <img src={fusion360}width="200px" height="auto" />
                </Col>

            </Row>


            <Contact />






      </Container>
      </>
    );
};
export default Telefoon;
