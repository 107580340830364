import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';




import iatihi1 from './iatihi/iatihi1.jpg'
import iatihi2 from './iatihi/iatihi2.jpg'
import iatihi3 from './iatihi/iatihi3.jpg'
import iatihi4 from './iatihi/iatihi4.jpg'
import iatihi5 from './iatihi/iatihi5.jpg'





import anycubic from '../img/icons/anycubic.png'
import arduino from '../img/icons/arduino.svg'
import fusion360 from '../img/icons/Fusion360.jpg'

const Iatihi = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | Iatihi';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="Iatihi"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                  <img src={iatihi1} width="90%" height="auto" />
               </Col>
              <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                <h3>De opdracht:</h3>
                <p>Voor dit vak moet je in je omgeving opzoek naar een ongebruiksvriendelijke fysieke gebruikersinteractie. Op basis van een aantal designregels uit het boek van Don Norman laat je hier een analyse op los om te ontdekken waar het fout gaat. Vervolgens ontwerp je aan de hand van dezelfde designregels een betere interface,die je tot slot bouwt en testjemet testpersonen om te kijken of je ontwerp daadwerkelijk gebruiksvriendelijk is.Van dit procesmaak je een verslag en een aantal schetsen, foto’s en video’s.</p>
              </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

              <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={iatihi2} />
                </div>
                <div>
                    <img src={iatihi3} />
                </div>
                <div>
                    <img src={iatihi4} />
                </div>
                <div>
                    <img src={iatihi5} />
                </div>


            </Carousel>

              </div>
              </Col>

              <Col className="mobile">
                <div>
                    <img src={iatihi2} />
                </div>
                <div>
                    <img src={iatihi3} />
                </div>
                <div>
                    <img src={iatihi4} />
                </div>
                <div>
                    <img src={iatihi5} />
                </div>

              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>

              <Row></Row>
                <Col className="center flexMiddle">
                  <img src={anycubic} width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={arduino}width="100px" height="auto" />
                </Col>

                <Col className="center flexMiddle">
                  <img src={fusion360}width="200px" height="auto" />
                </Col>

            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Iatihi;
