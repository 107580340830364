import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/home.css';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'



class Contact extends React.Component {


  render() {
    return <Row className="backgroundDark">
      <Row className="contactLine">
        <div className="titleMetStreep">
          <span className="sportstreep"></span>
          <h2>Contact</h2>
         </div>

      </Row>
      <Row xs={2} className="containerKennis fullWidthText paddingBottom">
        <Col>
          <h3>hallo@roelverlaan.nl</h3>
        </Col>
      </Row>
    </Row>
  }
}

export default Contact;
