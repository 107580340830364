import React from 'react';
import ReactDOM from 'react-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import '../styles/wiebenik.css'
import backgroundIk from "../img/RoelVerlaan.jpg"


function getAge(DUB) {
    var today = new Date();
    var birthDate = new Date(DUB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }

    return age;
}


class WiebenikLang extends React.Component {
  constructor(props) {
  super(props);
  this.state = {
    age:'2'
  };
this.state.age = getAge('3/18/1997');
}

    render() {
    return <Row className="wieBenIk">
      <Col xl={6} lg={6} className="centerFlex">
        <div className='fotoDubbel' > <img src={backgroundIk} width="100%" height="auto"/></div>
      </Col>
    <Col className="wieBenikTekst">
      <div className="smallTekst">
        <div className="blueLine">
        </div>
        <h2>WIE BEN IK?</h2>

        <p>Ik ben Roel Verlaan en ben <span class="getage">{this.state.age}</span> Jaar. Momenteel studeer ik Informatica aan de hogeschool te Leiden. Als specialisatie doe ik Interactie Technologie, binnen deze richting kan je alle kanten op ik krijg een stuk te leren over software (hoe het schrijven) en een stuk over hardware (het programmeren van o.a. arduino ’s).

 Persoonlijk vind ik het belangrijk dat een bedrijf mij de mogelijkheid kan bieden om zowel op hardware als op software niveau kan ontwikkelen. In mijn vrije tijd houd ik mij bezig met o.a. het printen met mijn 3d printer, motorrijden en Volleybal. Het doel dat ik voor mij gesteld heb is om beter te worden in het schrijven van software voor bijvoorbeeld hardware.</p>

      </div>

    </Col>
    </Row>;
  }
}

export default WiebenikLang;
