import React from 'react';
import ReactDOM from 'react-dom';
import Werk from './Werk';
import '../styles/home.css';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import '../styles/common.css'
import BigTitle from '../Algemeen/BigTitle'

import Detailcard from '../Portfolio/Detailcard';
import bonq from '../Detail/bonq/bonq1.jpg'
import iatihi from '../Detail/iatihi/iatihi1.jpg'
import brusco from '../Detail/brusco/brusco4.jpg'
import cherno from '../Detail/cherno/cherno1.jpg'
import cad from '../img/cad.jpg'
import labvr from '../Detail/labvr/labvr1.jpg'
import drone from '../Detail/drone/drone_14.jpeg'
import balans from '../img/wallie.jpg'
import render from '../Detail/render/render1.jpg'
import telefoon from '../img/telefoon.jpg'


class WerkContainer extends React.Component {
  render() {
    return <Row>
      <Row>


        <BigTitle bigTitle="Projecten" smallTitle="Mijn werk"  />

      </Row>

      <Row className="foto_container_responsive">
        <Col>

            <Detailcard  title="Chernobyl" urlLink="/cherno" filtername="software" imgurl={cherno}/>
            <Detailcard title="Bonq" urlLink="/bonq" filtername="software" imgurl={bonq}/>
            <Detailcard title="LabVR" urlLink="/labvr" filtername="software" imgurl={labvr}/>
            <Detailcard title="Minor - Balans robot" urlLink="/minor/balans" filtername="hardware software" imgurl={balans}/>
            <Detailcard title="3d modelleren & renderen" urlLink="/render" filtername="software" imgurl={render}/>
            <Detailcard title="Telefoon" urlLink="/telefoon" filtername="hardare software" imgurl={telefoon}/>


        </Col>
      </Row>
      <Row className="paddingBottom">
      
        <Col className="center">
          <div className="centerBlockButton">
            <a href="/portfolio"><button className="btn-BorderBottom"  size="sm">BEKIJK ALLES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>

          </div>


        </Col>


      </Row>
    </Row>
  }
}

export default WerkContainer;
