import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";


import Home from './Home';
import Biografie from './Biografie';
import Portfolio from './Portfolio';
import Minor_wallie from '../components/Detail/Minor_wallie';
import Minor_drone from '../components/Detail/Minor_drone';
import Labvr from '../components/Detail/Labvr.js';
import Bonq from '../components/Detail/Bonq.js';
import Brusco from '../components/Detail/Brusco.js';
import Cherno from '../components/Detail/Cherno.js';
import Cad from '../components/Detail/Cad.js';
import Render from '../components/Detail/Render.js';
import Telefoon from '../components/Detail/Telefoon.js';
import Iatihi from '../components/Detail/Iatihi.js';
import '../components/styles/mobile.css';

const Paginas = () => {
    return(
        <Router>

            <Route exact path="/" component={Home} />
            <Route path="/biografie" component = {Biografie} />
            <Route exact  path="/portfolio" component = {Portfolio} />
            <Route exact path="/portfolio/minor/balans" component = {Minor_wallie} />
            <Route exact path="/portfolio/minor/drone" component = {Minor_drone} />
            <Route exact path="/portfolio/labvr" component = {Labvr} />
            <Route exact path="/portfolio/bonq" component = {Bonq} />
            <Route exact path="/portfolio/brusco" component = {Brusco} />
            <Route exact path="/portfolio/cherno" component = {Cherno} />
            <Route exact path="/portfolio/cad" component = {Cad} />
            <Route exact path="/portfolio/render" component = {Render} />
            <Route exact path="/portfolio/telefoon" component = {Telefoon} />
            <Route exact path="/portfolio/iatihi" component = {Iatihi} />
        </Router>
    );
};

export default Paginas;
