import React, { useState } from "react";
import "../styles/portfolio.css";
import styled from "styled-components";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


Detailcard.defaultProps = {
  title: "dot grey",
  imgurl: "",
  filtername: "",
  urlLink: ""
}

export default function Detailcard(props) {
    return <>
    <a href={`/portfolio${props.urlLink}`}>
    <div class={`filter-item ${props.filtername}`}>
        <div className="container_foto ">
           <div className="popUpBar text-center">
              <span>Bekijk</span>
           </div>
           <div class="text-left textMoving">
              <h2>{props.title}</h2>
              <h4>Filter: {props.filtername}</h4>
           </div>
           <img src={props.imgurl} alt="" />
        </div>
      </div>
      </a>

    </>

}
