import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import '../styles/home.css'
import Button from 'react-bootstrap/Button'

const Intro = () => {

  useEffect(() => {
      document.title = 'Home';
  });

  return(
     <Row className="backgroundDark mobileReverse row-no-gutters">

        <Col xl={5} lg={12} sm={12}>
          <div className="dottedBackground positionLeftTop">&nbsp;</div>
          <div className="IntroName">
            <a href="/biografie" className="buttonHome">Ik ben een alles doener</a>
            <h1 className="bigH1">Roel Verlaan</h1>
            <h2 className="bigH2">Mijn naam is </h2>

         </div>
        </Col>
        <Col className="background-Intro">

        </Col>

    </Row>
  );
};

export default Intro;
