import React from 'react';
import ReactDOM from 'react-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'


TimelineItem.defaultProps = {
    title: "TWIN GRAPHIC",
    functie: "eigenaar",
    datum: "2016 - Heden",
    ontwikkeling: 'Skills ontwikkeld'
}

export default function TimelineItem(props) {
    return  <Row className="timeline-nodes">
      <Col xl={5} md={5} className="timeline-content">
        <h3>{props.title}</h3>
        <ul>
          <li>{props.functie}</li>
          <li>{props.datum}</li>
          <li>{props.ontwikkeling}</li>
        </ul>
      </Col>
      <Col >

      </Col>
    </Row>


  }
