import React from 'react';
import ReactDOM from 'react-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import '../styles/wiebenik.css'
import backgroundIk from "../img/RoelVerlaan.jpg"

class Wiebenik extends React.Component {
  render() {
    return <>
    <Row className="wieBenIk">
    <Col xl={7} lg={6}  className="centerFlex">
      <div className='fotoDubbel' > <img src={backgroundIk} width="100%" height="auto"/></div>
    </Col>
    <Col className="wieBenikTekst" >
      <div className="smallTekst">
        <div className="blueLine">

        </div>
        <h2>WIE BEN IK?</h2>

        <p>Ik ben Roel en ik wil u wat meer werk van mij laten zien. Op deze manier ziet u hoe ik denk en op welke manier ik te werk ga. Hopelijk vindt u alle informatie die u nodig heeft. Heeft u vragen? Stel ze gerust.</p>
          <div className="centerBlockButtonLeft">
            <a href="/biografie"><button className="btn-BorderBottom smallFont" size="sm">Bekijk Biografie&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>

          </div>
      </div>

    </Col>
    </Row>
    </>
  }
}

export default Wiebenik;
