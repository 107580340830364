import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/home.css';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


import Dots from './Dots'
import BigTitle from './BigTitle'


import angular from '../img/icons/Angular.png'
import anycubic from '../img/icons/anycubic.png'
import arduino from '../img/icons/arduino.svg'
import blender from '../img/icons/blender.png'
import cinema from '../img/icons/cinema.png'
import fritzing from '../img/icons/Fritzing.png'
import fusion360 from '../img/icons/Fusion360.jpg'
import illustrator from '../img/icons/illustrator.png'
import indesign from '../img/icons/indesign.svg'
import laravel from '../img/icons/Laravel.png'
import photoshop from '../img/icons/Photoshop.png'
import aframe from '../img/icons/aframe.png'
import reactjs from '../img/icons/reactjs.png'
import octane from '../img/icons/octane.png'
import redshift from '../img/icons/redshift.jpg'
import vray from '../img/icons/vray.png'

class KennisNieuw extends React.Component {
  constructor(props) {
  super(props);
  this.state = {
    points: 5,
    blue: 1,
  };
}
  render() {
    return <Row className="backgroundGray paddingBottom">
      <Row>
        <BigTitle bigTitle="Mijn" smallTitle="Kennis"  />
        <h3 className="center">Ik heb projecten gedaan met o.a.:<br/><br/></h3>
      </Row>
      <Row  className="containerKennis contactSlide">
        <Carousel dynamicHeight={false}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={20} showThumbs={false} >
          <div>
              <img src={angular} />
          </div>

          <div>
              <img src={anycubic} />
          </div>

          <div>
              <img src={arduino} />
          </div>

          <div>
              <img src={blender} />
          </div>
          <div>
              <img src={cinema} />
          </div>
          <div>
              <img src={fritzing} />
          </div>
          <div>
              <img src={fusion360} />
          </div>
          <div>
              <img src={illustrator} />
          </div>
          <div>
              <img src={indesign} />
          </div>
          <div>
              <img src={laravel} />
          </div>
          <div>
              <img src={photoshop} />
          </div>
          <div>
              <img src={aframe} />
          </div>
          <div>
              <img src={reactjs} />
          </div>
          <div>
              <img src={octane} />
          </div>
          <div>
              <img src={redshift} />
          </div>
          <div>
              <img src={vray} />
          </div>

      </Carousel>
        </Row>
    </Row>
  }
}

export default KennisNieuw;
