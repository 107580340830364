import './App.css';


import Paginas from './paginas';
function App() {
  return (
    <div>
      <Paginas />
    </div>
  );
}export default App;
