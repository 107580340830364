import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


import drone from './drone/drone_14.jpeg'


import drone1 from './drone/drone_1.jpg'
import drone2 from './drone/drone_2.jpg'
import drone3 from './drone/drone_3.png'
import drone4 from './drone/drone_4.png'
import drone5 from './drone/drone_5.jpeg'
import drone6 from './drone/drone_6.jpeg'
import drone7 from './drone/drone_7.jpeg'
import drone8 from './drone/drone_8.jpeg'
import drone9 from './drone/drone_9.jpeg'
import drone10 from './drone/drone_10.jpeg'
import drone11 from './drone/drone_11.jpeg'
import drone12 from './drone/drone_12.jpeg'
import drone13 from './drone/drone_13.jpeg'
import drone14 from './drone/drone_14.jpeg'
import drone15 from './drone/drone_15.jpeg'
import drone16 from './drone/drone_16.jpeg'


import anycubic from '../img/icons/anycubic.png'
import arduino from '../img/icons/arduino.svg'
import fritzing from '../img/icons/Fritzing.png'
import fusion360 from '../img/icons/Fusion360.jpg'

const Minor_drone = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | Minor - Drone';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="De vliegende wekker"  />
            </Row>

            <Row className="paddingBottom PaddingWide topRow mobileReverse">
            <Col xl={6} md={8} className="center paddingBottom">
                <img src={drone} width="90%" height="auto" />
                <p>&nbsp;</p>
                  <iframe width="90%" height="505px" src="https://www.youtube.com/embed/_yt-moLlSSk" title="Irob eindvideo" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
              <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                <h3>De opdracht:</h3>
              <p>Bedenk een experiment die de volgende onderdelen minimaal bevat: </p>
                <ul>
                  <li>	Actuatoren + sturing / beïnvloeding van fysieke omgeving </li>
                  <li>	Firmware/embedded/zelf-contained-system (je moet niet een laptop erbij verkopen om het te laten werken). </li>
                  <li>	Experimentele component (praktijkonderzoek), hoe en of de techniek werkt en of het kan. </li>
                  <li>	Interactie met context (fysiek/gebruiker of applicatie) </li>
                </ul>
                <h4>De uitwerking:</h4>
                <p>Het concept van een vliegende wekker vloeide voort uit twee ideeën samengevoegd tot één. Roel wilde een drone bouwen die een speciale taak kon uitvoeren en Amos wilde een wekker bouwen die hem uit bed kon halen én houden. Deze twee ideeën maakten samen het concept van een wekker die registreert of de gebruiker op bed ligt en op ingestelde tijdsperiode een drone als alarm laat wegvliegen, waarna de gebruiker deze dient terug op te pakken om hem uit te zetten.</p>
                <p>Het experimentele van dit project zijn het draadloos samenvoegen en laten -werken van een drone, druksensoren en een web interface om de hele installatie functioneel te maken. Na het analyseren van het kritische pad omtrent de drone, besloten we deze in dit project zelf te ontwikkelen, i.p.v. bijvoorbeeld een kant-en-klare drone te gebruiken en modificeren, mede omdat dit meer vrijheid en vooral uitdaging biedt in het experimentele gebied van dit project.Voor de draadloze verbinding van de installatie is gebruik gemaakt van een Raspberry Pi, in combinatie met Flask, om de interface en communicatie te hosten. </p>
              </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

              <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={drone1} />
                </div>
                <div>
                    <img src={drone6} />
                </div>
                <div>
                    <img src={drone13} />
                </div>
                <div>
                    <img src={drone16} />
                </div>
            </Carousel>

              </div>
              </Col>
              <Col className="mobile">
                <div>
                    <img src={drone1} />
                </div>
                <div>
                    <img src={drone6} />
                </div>
                <div>
                    <img src={drone13} />
                </div>
                <div>
                    <img src={drone16} />
                </div>

              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>
              <Row></Row>

                <Col className="center flexMiddle">
                  <img src={anycubic} width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={arduino}width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={fritzing} width="125px" height="auto"/>
                </Col>
                <Col className="center flexMiddle">
                  <img src={fusion360}width="200px" height="auto" />
                </Col>


            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Minor_drone;
