import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';




import bonq1 from './bonq/bonq1.jpg'
import bonq2 from './bonq/bonq2.jpg'
import bonq3 from './bonq/bonq3.png'




import reactjs from '../img/icons/reactjs.png'

const Bonq = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | Bonq';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="Bonq"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                <iframe width="90%" height="505px" src="https://www.youtube.com/embed/DQTOl9QtglQ" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
              <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                <h3>De opdracht:</h3>
                <p>In deze periode ga je onderzoek doen vanuit het perspectief van een persoon die normaliter actief is m.b.t. activiteiten  in  een  vereniging,  maar  noodgedwongen  thuis  zit  vanwege  de  pandemie.  Je  bouwt  empathie voor  deze  gebruiker  op  en  maakt  aan  het  eind  van  deze  fase  een onder  andere  een  problem  statement, persona en customer journey. Op basis van deze middelen bedenk, realiseer en test je een hi-fi prototypedie ingezet kan worden om het probleem van thuiszitten (zoveel mogelijk) te verhelpen.</p>
              </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

              <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom ">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={bonq1} />
                </div>
                <div>
                    <img src={bonq2} />
                </div>
                <div>
                    <img src={bonq3} />
                </div>

            </Carousel>

              </div>
              </Col>

                              <Col className="mobile">
                                <div>
                                    <img src={bonq1} />
                                </div>
                                <div>
                                    <img src={bonq2} />
                                </div>
                                <div>
                                    <img src={bonq3} />
                                </div>
                              </Col>

            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>
              <Row></Row>
                <Col className="center flexMiddle">
                  <img src={reactjs} width="150px" height="auto" />
                </Col>


            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Bonq;
