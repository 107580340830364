import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../components/styles/common.css'
import Header from '../components/Algemeen/Header';
import Werk from '../components/Portfolio/Werk';
import BigTitle from '../components/Algemeen/BigTitle'

const Portfolio = () => {
    useEffect(() => {
        document.title = 'Portfolio';
    });

    return (
      <Container fluid="true">
            <header >
              <Header />
            </header>
            <BigTitle bigTitle="Projecten" smallTitle="Mijn Werk"  />
            <Werk />




      </Container>
    );
};export default Portfolio;
