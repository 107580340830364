import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../components/styles/common.css'
import Header from '../components/Algemeen/Header';
import WiebenikLang from '../components/Biografie/WiebenikLang';
import Ervaring from '../components/Biografie/Ervaring';
import Contact from '../components/Algemeen/Contact';
import KennisNieuw from '../components/Algemeen/KennisNieuw'
const Biografie = () => {
    useEffect(() => {
        document.title = 'Biografie';
    });

    return (
      <Container fluid="true">
            <header >
              <Header />
            </header>

            <WiebenikLang />
            <Ervaring />

            <KennisNieuw />
            <Contact />





      </Container>
    );
};export default Biografie;
