import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/home.css';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Dots from './Dots'
import BigTitle from './BigTitle'

class Kennis extends React.Component {
  constructor(props) {
  super(props);
  this.state = {
    points: 5,
    blue: 1,
  };
}
  render() {
    return <Row className="backgroundGray paddingBottom">
      <Row>
        <BigTitle bigTitle="Mijn" smallTitle="Kennis"  />

      </Row>
      <Row xs={2} className="containerKennis">
        <Col>
          <h3>Adobe</h3>
          <p>
          </p>

          <Row>

                <Col xs={4}>  Photoshop </Col>
                <Col>
                  {Array(2).fill(<Dots color="dot blueColor" />)}
                  {Array(3).fill(<Dots />)}
                </Col>
          </Row>
          <Row>
                <Col xs={4}>  Illustrator </Col>
                  <Col>
                    {Array(2).fill(<Dots color="dot blueColor" />)}
                    {Array(3).fill(<Dots />)}
                  </Col>

          </Row>
          <Row>
                <Col xs={4}>  Indesign </Col>
                  <Col>
                    {Array(2).fill(<Dots color="dot blueColor" />)}
                    {Array(3).fill(<Dots />)}
                  </Col>

          </Row>
          <Row>
                <Col xs={4}>  Adobe XD </Col>
                  <Col>
                    {Array(2).fill(<Dots color="dot blueColor" />)}
                    {Array(3).fill(<Dots />)}
                  </Col>

          </Row>


        </Col>
        <Col>
          <h3>Code</h3>
                   <Row>
                        <Col xs={4}>  HTML </Col>
                          <Col>
                            {Array(2).fill(<Dots color="dot blueColor" />)}
                            {Array(3).fill(<Dots />)}
                          </Col>

                  </Row>
                  <Row>
                        <Col xs={4}>  CSS </Col>
                          <Col>
                            {Array(2).fill(<Dots color="dot blueColor" />)}
                            {Array(3).fill(<Dots />)}
                          </Col>

                  </Row>
                  <Row>
                        <Col xs={4}>  PHP </Col>
                          <Col>
                            {Array(2).fill(<Dots color="dot blueColor" />)}
                            {Array(3).fill(<Dots />)}
                          </Col>
                  </Row>
                  <Row>
                        <Col xs={4}>  Javascript </Col>
                          <Col>
                            {Array(2).fill(<Dots color="dot blueColor" />)}
                            {Array(3).fill(<Dots />)}
                          </Col>

                  </Row>
                  <Row>
                        <Col xs={4}>  Python </Col>
                          <Col>
                            {Array(2).fill(<Dots color="dot blueColor" />)}
                            {Array(3).fill(<Dots />)}
                          </Col>

                  </Row>
                  <Row>
                        <Col xs={4}>  Java </Col>
                          <Col>
                            {Array(2).fill(<Dots color="dot blueColor" />)}
                            {Array(3).fill(<Dots />)}
                          </Col>

                  </Row>
        </Col>
      </Row>
    </Row>
  }
}

export default Kennis;
