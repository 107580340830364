import React, { useState,useEffect } from "react";
import "../styles/portfolio.css";
import styled from "styled-components";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Isotope from "isotope-layout";
import Detailcard from './Detailcard';

import bonq from '../Detail/bonq/bonq1.jpg'
import iatihi from '../Detail/iatihi/iatihi1.jpg'
import brusco from '../Detail/brusco/brusco4.jpg'
import cherno from '../Detail/cherno/cherno1.jpg'
import cad from '../img/cad.jpg'
import labvr from '../Detail/labvr/labvr1.jpg'
import drone from '../Detail/drone/drone_14.jpeg'
import balans from '../img/wallie.jpg'
import render from '../Detail/render/render1.jpg'
import telefoon from '../img/telefoon.jpg'

const Werk = () => {
  // init one ref to store the future isotope object
  const isotope = React.useRef()
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = React.useState('*')



  function test() {

  }
  // initialize an Isotope object with configs
  React.useEffect( () => {
    document.title = 'Portfolio';
    setTimeout(()=>{
      isotope.current = new Isotope('.filter-container', {
        itemSelector: '.filter-item',
        layoutMode: 'fitRows',
        stagger: 30,
        percentPosition: true,
        fitWidth: true,
        resize:true,
      })
      // cleanup
      return () => isotope.current.destory();
    }, 150)
  }, [])

  // handling filter key change
  React.useEffect(() => {
    setTimeout(()=>{
      filterKey === '*'
        ? isotope.current.arrange({filter: `*`})
        : isotope.current.arrange({filter: `.${filterKey}`});
    }, 155)

  }, [filterKey])

  const handleFilterKeyChange = key => () => setFilterKey(key)

  return (
    <>
    <Row className="portfolio_list">
      <ul className="listPortfolio">
        <li onClick={handleFilterKeyChange('*')}>Alles</li>
        <li onClick={handleFilterKeyChange('hardware')}>Hardware</li>
        <li onClick={handleFilterKeyChange('software')}>Software</li>
      </ul>
      <hr />
      <ul className="filter-container ">
          <div class="grid-sizer"></div>

          <Detailcard title="Brusco" urlLink="/brusco" filtername="hardware software" imgurl={brusco}/>
          <Detailcard title="Chernobyl" urlLink="/cherno" filtername="software" imgurl={cherno}/>
          <Detailcard title="Bonq" urlLink="/bonq" filtername="software" imgurl={bonq}/>
          <Detailcard title="Iatihi" urlLink="/iatihi" filtername="hardware" imgurl={iatihi}/>
          <Detailcard title="CAD" urlLink="/cad" filtername="software" imgurl={cad}/>
          <Detailcard title="LabVR" urlLink="/labvr" filtername="software" imgurl={labvr}/>
          <Detailcard title="Minor - De vliegende wekker" urlLink="/minor/drone" filtername="hardware software" imgurl={drone}/>
          <Detailcard title="Minor - Balans robot" urlLink="/minor/balans" filtername="hardware software" imgurl={balans}/>
          <Detailcard title="3d modelleren & renderen" urlLink="/render" filtername="software" imgurl={render}/>
          <Detailcard title="Telefoon" urlLink="/telefoon" filtername="hardare software" imgurl={telefoon}/>

            </ul>
    </Row>
    </>
  )
}
export default Werk;
