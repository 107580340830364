import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


import cherno1 from './cherno/cherno1.jpg'
import cherno2 from './cherno/cherno2.jpg'
import cherno3 from './cherno/cherno3.jpg'
import cherno4 from './cherno/cherno4.jpg'



import html from '../img/icons/html.png'
import js from '../img/icons/js.jpg'
import css from '../img/icons/css.png'

const Cherno = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | Chernobyl';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="chernobyl"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                <img src={cherno1} width="90%" height="auto" />
              </Col>
              <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                <h3>De opdracht:</h3>
                <p>Jouw opdracht is om een interactieve webapplicatie te bouwen voor jongeren van jouwleeftijd, die de achtergrond van een nieuwsverhaal op interactieve wijze verteld en rekening houdt met de bovenstaandedrie vragen. De gebruiker moet namelijk invloed hebben op de manier waarop ditverhaal verteld wordt. Deze applicatie ontwerp jemobile-first: de versie voor mobiele telefoonsstaat centraal. Pas als de mobiele versie werktpasjede applicatie aanzodat het ook optablet endesktop een goede ervaring geeft.</p>
              </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

             <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={cherno2} />
                </div>
                <div>
                    <img src={cherno3} />
                </div>
                <div>
                    <img src={cherno4} />
                </div>


            </Carousel>

              </div>
              </Col>


              <Col className="mobile">
                <div>
                    <img src={cherno2} />
                </div>
                <div>
                    <img src={cherno3} />
                </div>
                <div>
                    <img src={cherno4} />
                </div>

              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>

              <Row></Row>
                <Col className="center flexMiddle">
                  <img src={html} width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={css}width="80px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={js} width="100px" height="auto"/>
                </Col>



            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Cherno;
