import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


import drone from './drone/drone_14.jpeg'


import wallie1 from './balans/wallie1.jpg'
import wallie2 from './balans/wallie2.jpg'
import wallie3 from './balans/wallie3.jpg'
import wallie4 from './balans/wallie4.png'



import anycubic from '../img/icons/anycubic.png'
import arduino from '../img/icons/arduino.svg'
import fusion360 from '../img/icons/Fusion360.jpg'

const Minor_wallie = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | Minor - Balans';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="Balans"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                <iframe width="90%" height="505px" src="https://www.youtube.com/embed/xsfzg116GJU" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </Col>
              <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                <h3>De opdracht:</h3>
                <p>Het doel is om een werkende zelf balancerende robot te maken.</p>
              </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

            <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={wallie1} />
                </div>
                <div>
                    <img src={wallie2} />
                </div>
                <div>
                    <img src={wallie3} />
                </div>
                <div>
                    <img src={wallie4} />
                </div>
            </Carousel>

              </div>
              </Col>
              <Col className="mobile">
                <div>
                    <img src={wallie1} />
                </div>
                <div>
                    <img src={wallie2} />
                </div>
                <div>
                    <img src={wallie3} />
                </div>
                <div>
                    <img src={wallie4} />
                </div>

              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>

              <Row></Row>
                <Col className="center flexMiddle">
                  <img src={anycubic} width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={arduino}width="100px" height="auto" />
                </Col>

                <Col className="center flexMiddle">
                  <img src={fusion360}width="200px" height="auto" />
                </Col>

            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Minor_wallie;
