import React from 'react';
import ReactDOM from 'react-dom';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import logo from '../../logo.svg';
import Col from 'react-bootstrap/Col'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";


class Header extends React.Component {
  render() {
    return <Navbar collapseOnSelect expand="lg"  variant="dark">
      <Col xs={5} className="backgroundDark centeredContent">
        <Navbar.Brand href="/"><img src={logo} width="75px" height="75px" /></Navbar.Brand>
      </Col>
      <Col  >

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">

            <NavLink exact to="/">Home</NavLink>
            <NavLink to="/biografie">Biografie</NavLink>
            <NavLink to="/portfolio">Portfolio</NavLink>
          </Nav>

        </Navbar.Collapse>
      </Col>


</Navbar>;
  }
}

export default Header;
