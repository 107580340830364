import React from 'react';
import ReactDOM from 'react-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'


BigTitle.defaultProps = {
  bigTitle: "Hello",
  smallTitle: "Small"
}

export default function BigTitle(props) {
    return  <div className="titleMetStreep">
              <h2>{props.bigTitle}</h2>
              <span className="sportstreep"></span>
              <h3>{props.smallTitle}</h3>
            </div>


  }
