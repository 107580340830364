import React from 'react';
import ReactDOM from 'react-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import '../styles/wiebenik.css'
import backgroundIk from "../img/RoelVerlaan.jpg"
import '../styles/biografie.css';

import TimelineItem from './TimelineItem';
import BigTitle from '../Algemeen/BigTitle';


class Ervaring extends React.Component {
  constructor(props) {
  super(props);
  this.state = {
    age:'2'
  };
}

    render() {
    return <Row className="Biografie">
      <BigTitle bigTitle="Mijn" smallTitle="Ervaring"  />

        <div class="timeline">
            <TimelineItem title="Hogeschool Leiden" functie="HBO" datum="2018 - Heden" ontwikkeling="Verwachte afstuudeerdatum: 2022, keuzvakken: IKFRAM (udemy Angular), IKREFACT (refacteren van code), IKDOCK (docker), ITRAPP(taal), Minor: Robotica" />
            <TimelineItem title="Grafisch Lyceum Rottedam" functie="MBO" datum="2013 - 2017" ontwikkeling="Behaald: Ja"/>
            <TimelineItem title="Scala College" functie="VMBO-T" datum="2009 - 2013" ontwikkeling="Behaald: Ja"/>
        </div>
        <h3 className="center paddingTopBottom">Werkervaring:</h3>
        <div class="timeline">
            <TimelineItem title="Twin Graphic" functie="Functie: Eigenaar" datum="2017 - Heden" ontwikkeling=""/>
            <TimelineItem title="Webstijl B.V." functie="Systeembeheerder" datum="2016 - Heden (extern)" ontwikkeling=""/>
            <TimelineItem title="Gamma Alphen aan den Rijn" functie="Verkoop medewerker" datum="2013 - Heden" ontwikkeling=""/>
            <TimelineItem title=" Hogeschool Leiden" functie="Student Assistent" datum="2019 - 2021" ontwikkeling=""/>
        </div>

    </Row>;
  }
}

export default Ervaring;
