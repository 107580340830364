import React from 'react';
import ReactDOM from 'react-dom';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'


Dots.defaultProps = {
  color: "dot grey"
}

export default function Dots(props) {
    return  <span className={props.color} ></span>



  }
