import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Intro from '../components/Home/Intro';
import Header from '../components/Algemeen/Header';
import Wiebenik from '../components/Home/Wiebenik';
import WerkContainer from '../components/Home/WerkContainer';
import KennisNieuw from '../components/Algemeen/KennisNieuw';
import Contact from '../components/Algemeen/Contact';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../components/styles/common.css'


const Home = () => {
    return (
      <Container fluid="true" noGutters="true">
            <header >
              <Header />
            </header>

            <Intro  />
            <Wiebenik />
            <WerkContainer />
            <KennisNieuw />
            <Contact />


      </Container>
    );
};export default Home;
