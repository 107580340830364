import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';




import cad1 from './cad/cad1.jpg'
import cad2 from './cad/cad2.jpg'
import cad3 from './cad/cad3.jpg'
import cad4 from './cad/cad4.jpg'
import cad5 from './cad/cad5.jpg'
import cad6 from './cad/cad6.jpg'





import fusion360 from '../img/icons/Fusion360.jpg'

const Cad = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | CAD';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="CAD"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                  <img src={cad1} width="90%" height="auto" />
               </Col>
               <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                 <h3>Het overzicht: </h3>
                 <p>Hiernaast en onder kunt een greep vinden uit de gemaakte CAD tekeningen van verschillende hobby projecten</p>
               </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

              <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={cad2} />
                </div>
                <div>
                    <img src={cad3} />
                </div>
                <div>
                    <img src={cad4} />
                </div>
                <div>
                    <img src={cad5} />
                </div>
                <div>
                    <img src={cad6} />
                </div>

            </Carousel>

              </div>
              </Col>

              <Col className="mobile">
                <div>
                    <img src={cad2} />
                </div>
                <div>
                    <img src={cad3} />
                </div>
                <div>
                    <img src={cad4} />
                </div>
                <div>
                    <img src={cad5} />
                </div>
                <div>
                    <img src={cad6} />
                </div>
              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>

              <Row></Row>
                <Col className="center flexMiddle">
                  <img src={fusion360}width="200px" height="auto" />
                </Col>

            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Cad;
