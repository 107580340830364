import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../styles/common.css'
import Header from '../Algemeen/Header';
import Contact from '../Algemeen/Contact';
import Kennis from '../Algemeen/Kennis'
import '../styles/detail.css'
import {row, Col} from 'react-bootstrap'

import BigTitle from '../Algemeen/BigTitle'

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';




import render1 from './render/render1.jpg'
import render2 from './render/render2.jpg'
import render3 from './render/render3.jpg'
import render4 from './render/render4.jpg'
import render5 from './render/render5.png'
import render6 from './render/render6.jpg'






import cinema from '../img/icons/cinema.png'
import octane from '../img/icons/octane.png'
import redshift from '../img/icons/redshift.jpg'
import vray from '../img/icons/vray.png'

const Render = () => {
  var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
    useEffect(() => {
        document.title = 'Portfolio | Renders';
    });


    return (
      <>

      <Container fluid >
        <header>
          <Header />
        </header>
            <Row>
              <BigTitle bigTitle="Project" smallTitle="Render"  />
            </Row>




            <Row className="paddingBottom PaddingWide topRow mobileReverse">
              <Col xl={6} md={8} className="center paddingBottom">
                  <img src={render1} width="90%" height="auto" />
               </Col>
              <Col xl={6} md={8} className="containerMarginCenter paddingBottom">
                <h3>Het overzicht: </h3>
                <p>Hiernaast en onder kunt een greep vinden uit de gemaakte modellen en renders van verschillende onderelen van mijn huis</p>
              </Col>
            </Row>

            <Row className="PaddingWide backgroundGray">
              <h2 className="center paddingTopBottom">Foto's</h2>

              <Col className="carouselFotos">
              <div className="containerCarousel paddingBottom">
              <Carousel dynamicHeight={true}  autoPlay={true} interval={5000} infiniteLoop={true} centerMode={true} centerSlidePercentage={50}  showThumbs={false} >
                <div>
                    <img src={render2} />
                </div>
                <div>
                    <img src={render3} />
                </div>
                <div>
                    <img src={render4} />
                </div>
                <div>
                    <img src={render5} />
                </div>
                <div>
                    <img src={render6} />
                </div>

            </Carousel>

              </div>
              </Col>
              <Col className="mobile">
                <div>
                    <img src={render2} />
                </div>
                <div>
                    <img src={render3} />
                </div>
                <div>
                    <img src={render4} />
                </div>
                <div>
                    <img src={render5} />
                </div>
                <div>
                    <img src={render6} />
                </div>

              </Col>
            </Row>

            <Row className="PaddingWide paddingGemaakt">

              <div className="smallTekst flexCenter">
                <div className="blueLine"></div>
                <h2 className="smallPaddingBottom">Gemaakt met</h2>

              </div>

              <Row></Row>
                <Col className="center flexMiddle">
                  <img src={cinema} width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={octane}width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={redshift}width="100px" height="auto" />
                </Col>
                <Col className="center flexMiddle">
                  <img src={vray}width="100px" height="auto" />
                </Col>


            </Row>


            <Contact />






      </Container>
      </>
    );
};export default Render;
